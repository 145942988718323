




























































































































































// TODO
// This needs to to be refactored:
/**
 * from this usage
<comp :contactName="item.contactName" :contactEmail="item.contactEmail"></comp>
// to this
<comp :data="item"></comp>
 */

import { Component, Vue, Prop } from "vue-property-decorator";
import truncate from "lodash/truncate";
import { TradeshowCompanyObject } from "@/types/interfaces";

@Component({})
export default class ExhibitorResultCard extends Vue {
    activeBorder = "border-transparent";

    @Prop({
        default: function() {
            return {};
        },
        required: true
    })
    data!: TradeshowCompanyObject;

    @Prop({ default: "row" })
    layout!: string;

    @Prop({
        default: ""
    })
    name!: string;

    @Prop({
        default: ""
    })
    imgPath!: string;

    @Prop({
        default: ""
    })
    id!: string;

    @Prop({
        default: ""
    })
    contactName!: string;

    @Prop({
        default: ""
    })
    contactEmail!: string;

    @Prop({
        default: false
    })
    hasFullInfo!: string;

    @Prop({
        default: false
    })
    hasActionButtons!: string;

    @Prop({
        default: true
    })
    showDescription!: boolean;

    @Prop({})
    imgAlt!: string;

    /**
     * Computed
     */
    get getLayoutOptions(): boolean {
        return this.$store.getters.layoutOptions;
    }

    get sectionToUse(): boolean {
        return this.$store.getters.getPageOptions("tradeshow").section
            .companyProfile;
    }

    get myExcerpt() {
        const description = this.data?.companyDescription || "";
        const returnValue = Vue.prototype.MgSanitize(description, true);

        return truncate(returnValue, { length: 200 });
    }

    get companyName() {
        return this.data && this.data.companyName ? this.data.companyName : "";
    }

    get detailTitle() {
        return this.companyName
            ? `View ${this.companyName} Exhibitor Profile`
            : "View Exhibitor Profile";
    }

    get detailsRouteObj() {
        return {
            name: "ExhibitorDetails",
            params: {
                id: this.id
            }
        };
    }

    /**
     * Methods
     */
}
